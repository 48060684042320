// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/findings/FindingBadgesTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.findings.FindingBadgesTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$LinkTemplate from './../../commons/LinkTemplate.soy.generated.js';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {!$findingBadgeRed.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $findingBadgeRed = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingBadgeRed$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.churn, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number} churn
 * @param {null|string|undefined=} link
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingBadgeRed$ = function($$areYouAnInternalCaller, $ijData, churn, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadgeRed']) {
    return soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadgeRed']({churn: churn, link: link}, $ijData);
  }
  soy.assertParamType(typeof churn === 'number', 'churn', churn, '@param', 'number');
  soy.assertParamType(link == null || typeof link === 'string', 'link', link, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, 'red', 'New findings', 'warning circle icon', churn, link));
};
export { $findingBadgeRed as findingBadgeRed };
export { $findingBadgeRed$ as findingBadgeRed$ };
/**
 * @typedef {{
 *  churn: number,
 *  link?: (null|string|undefined),
 * }}
 */
$findingBadgeRed.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingBadgeRed.soyTemplateName = 'ts.perspectives.findings.FindingBadgesTemplate.findingBadgeRed';
}


/**
 * @param {!$findingBadgeBlue.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $findingBadgeBlue = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingBadgeBlue$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.churn, opt_data.link, opt_data.badgeForSpecItemFindings);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number} churn
 * @param {null|string|undefined=} link
 * @param {boolean|null|undefined=} badgeForSpecItemFindings
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingBadgeBlue$ = function($$areYouAnInternalCaller, $ijData, churn, link, badgeForSpecItemFindings) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadgeBlue']) {
    return soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadgeBlue']({churn: churn, link: link, badgeForSpecItemFindings: badgeForSpecItemFindings}, $ijData);
  }
  soy.assertParamType(typeof churn === 'number', 'churn', churn, '@param', 'number');
  soy.assertParamType(link == null || typeof link === 'string', 'link', link, '@param', 'null|string|undefined');
  soy.assertParamType(badgeForSpecItemFindings == null || typeof badgeForSpecItemFindings === 'boolean', 'badgeForSpecItemFindings', badgeForSpecItemFindings, '@param', 'boolean|null|undefined');
  let $output = '';
  let badgeTitle__soy6350 = '' + (badgeForSpecItemFindings ? 'Unresolved findings in changed Spec Items' : 'Unresolved findings in changed methods');
  $output += $findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, 'blue', badgeTitle__soy6350, 'write square icon', churn, link);
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $findingBadgeBlue as findingBadgeBlue };
export { $findingBadgeBlue$ as findingBadgeBlue$ };
/**
 * @typedef {{
 *  churn: number,
 *  link?: (null|string|undefined),
 *  badgeForSpecItemFindings?: (boolean|null|undefined),
 * }}
 */
$findingBadgeBlue.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingBadgeBlue.soyTemplateName = 'ts.perspectives.findings.FindingBadgesTemplate.findingBadgeBlue';
}


/**
 * @param {!$findingBadgeGreen.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $findingBadgeGreen = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingBadgeGreen$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.churn, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number} churn
 * @param {null|string|undefined=} link
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingBadgeGreen$ = function($$areYouAnInternalCaller, $ijData, churn, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadgeGreen']) {
    return soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadgeGreen']({churn: churn, link: link}, $ijData);
  }
  soy.assertParamType(typeof churn === 'number', 'churn', churn, '@param', 'number');
  soy.assertParamType(link == null || typeof link === 'string', 'link', link, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, 'green', 'Resolved findings', 'check square icon', churn, link));
};
export { $findingBadgeGreen as findingBadgeGreen };
export { $findingBadgeGreen$ as findingBadgeGreen$ };
/**
 * @typedef {{
 *  churn: number,
 *  link?: (null|string|undefined),
 * }}
 */
$findingBadgeGreen.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingBadgeGreen.soyTemplateName = 'ts.perspectives.findings.FindingBadgesTemplate.findingBadgeGreen';
}


/**
 * @param {!$badgeAlerts.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $badgeAlerts = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $badgeAlerts$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.churn, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number} churn
 * @param {null|string|undefined=} link
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $badgeAlerts$ = function($$areYouAnInternalCaller, $ijData, churn, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.badgeAlerts']) {
    return soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.badgeAlerts']({churn: churn, link: link}, $ijData);
  }
  soy.assertParamType(typeof churn === 'number', 'churn', churn, '@param', 'number');
  soy.assertParamType(link == null || typeof link === 'string', 'link', link, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, 'orange', 'Commit alerts', 'bell icon', churn, link));
};
export { $badgeAlerts as badgeAlerts };
export { $badgeAlerts$ as badgeAlerts$ };
/**
 * @typedef {{
 *  churn: number,
 *  link?: (null|string|undefined),
 * }}
 */
$badgeAlerts.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $badgeAlerts.soyTemplateName = 'ts.perspectives.findings.FindingBadgesTemplate.badgeAlerts';
}


/**
 * @param {!$findingBadge.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $findingBadge = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $findingBadge$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.color, opt_data.title, opt_data.icon, opt_data.churn, opt_data.link);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} color
 * @param {string} title
 * @param {?} icon
 * @param {number} churn
 * @param {null|string|undefined=} link
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $findingBadge$ = function($$areYouAnInternalCaller, $ijData, color, title, icon, churn, link) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadge']) {
    return soy.$$stubsMap['ts.perspectives.findings.FindingBadgesTemplate.findingBadge']({color: color, title: title, icon: icon, churn: churn, link: link}, $ijData);
  }
  soy.assertParamType(typeof color === 'string', 'color', color, '@param', 'string');
  soy.assertParamType(typeof title === 'string', 'title', title, '@param', 'string');
  soy.assertParamType(typeof churn === 'number', 'churn', churn, '@param', 'number');
  soy.assertParamType(link == null || typeof link === 'string', 'link', link, '@param', 'null|string|undefined');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<span' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + title)) + ' class="finding badge ' + soy.$$escapeHtmlAttribute(color) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.findings.FindingBadgesTemplate.findingBadge ts/perspectives/findings/FindingBadgesTemplate.soy:115"' : '') + '>' + (link ? '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(link)) + '" class="finding badge ' + soy.$$escapeHtmlAttribute(color) + '">' : '') + '<i class="' + soy.$$escapeHtmlAttribute(icon) + '"></i> ' + soy.$$escapeHtml(churn) + (link ? '</a>' : '') + '</span>');
};
export { $findingBadge as findingBadge };
export { $findingBadge$ as findingBadge$ };
/**
 * @typedef {{
 *  color: string,
 *  title: string,
 *  icon: ?,
 *  churn: number,
 *  link?: (null|string|undefined),
 * }}
 */
$findingBadge.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $findingBadge.soyTemplateName = 'ts.perspectives.findings.FindingBadgesTemplate.findingBadge';
}
